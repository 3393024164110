<div class="mx-auto w-full max-w-screen-2xl px-6 2xl:px-0 pb-16 pt-44 lg:pt-48 sm:pb-24">
        <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
            <div class="flex flex-column">
                <div class="flex-auto">
                    <div class="grid sm:grid-cols-12 grid-cols-6 gap-x-8 mx-2 space-y-6 sm:space-y-0">
                        <div class="col-span-5 items-center h-full flex mt-2">
                            <div>
                                <h3 class="text-lg font-semibold leading-6 text-gray-900" id="modal-title">Wachtwoord reset</h3>
                                <div class="mt-4">
                                    <p class="text-sm text-gray-500">Stel een nieuw wachtwoord in voor je account. Zorg ervoor dat je wachtwoord minimaal 6 tekens bevat.</p>
                                </div>
                                <dl class="mt-10 sm:mb-8 max-w-xl space-y-8 text-sm leading-6 text-gray-600 lg:max-w-none">
                                    <div class="relative pl-9">
                                        <dt class="inline font-semibold text-gray-900">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="absolute left-1 top-1 h-5 w-5 text-green-500">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"/>
                                            </svg>
                                            Tip
                                        </dt>
                                        <dd class="inline">gebruik een sterk wachtwoord met hoofdletters, kleine letters, cijfers en speciale tekens.</dd>
                                    </div>
                                </dl>
                            </div>
                        </div>

                        <div class="col-span-1">
                            <!-- Spacer -->
                        </div>

                        <div class="col-span-6 flex items-center justify-center flex-col">
                            <div class="w-full space-y-6 mb-6">
                                <div>
                                    <label for="email" class="block text-sm font-medium leading-6 text-black">E-mailadres</label>
                                    <div class="mt-2">
                                        <input id="email"
                                               formControlName="email"
                                               type="email"
                                               required
                                               class="w-full rounded-sm border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-gray-400 sm:text-sm sm:leading-6">
                                    </div>
                                    <div class="text-red-400 text-sm mt-1" *formControlInvalid="resetPasswordForm.get('email')">
                                        <p *formControlInvalid="resetPasswordForm.get('email'); error: 'required'">E-mailadres is verplicht.</p>
                                        <p *formControlInvalid="resetPasswordForm.get('email'); error: 'email'">Voer een geldig e-mailadres in.</p>
                                    </div>
                                </div>

                                <div>
                                    <label for="password" class="block text-sm font-medium leading-6 text-black">Nieuw wachtwoord</label>
                                    <div class="mt-2">
                                        <input id="password"
                                               formControlName="password"
                                               type="password"
                                               required
                                               class="w-full rounded-sm border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-gray-400 sm:text-sm sm:leading-6">
                                    </div>
                                    <div class="text-red-400 text-sm mt-1" *formControlInvalid="resetPasswordForm.get('password')">
                                        <p *formControlInvalid="resetPasswordForm.get('password'); error: 'required'">Wachtwoord is verplicht.</p>
                                        <p *formControlInvalid="resetPasswordForm.get('password'); error: 'minlength'">Wachtwoord moet minimaal 6 tekens bevatten.</p>
                                    </div>
                                </div>

                                <div>
                                    <label for="passwordConfirmation" class="block text-sm font-medium leading-6 text-black">Bevestig wachtwoord</label>
                                    <div class="mt-2">
                                        <input id="passwordConfirmation"
                                               formControlName="passwordConfirmation"
                                               type="password"
                                               required
                                               class="w-full rounded-sm border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-gray-400 sm:text-sm sm:leading-6">
                                    </div>
                                    <div class="text-red-400 text-sm mt-1" *formControlInvalid="resetPasswordForm.get('passwordConfirmation')">
                                        <p *formControlInvalid="resetPasswordForm.get('passwordConfirmation'); error: 'required'">Bevestig je wachtwoord.</p>
                                        <p *formControlInvalid="resetPasswordForm.get('passwordConfirmation'); error: 'mustMatch'">Wachtwoorden komen niet overeen.</p>
                                    </div>
                                </div>

                                @if (errorMessage()) {
                                    <div class="text-red-400 text-sm">
                                        {{ errorMessage() }}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="sm:flex sm:flex-row-reverse mt-8">
                <div class="flex-grow sm:flex items-center gap-x-5 flex-row-reverse gap-y-4 sm:gap-y-0">
                    <button
                        type="submit"
                        [disabled]="resetPasswordForm.invalid || isLoading()"
                        class="inline-flex w-full justify-center rounded-sm bg-red-600 px-3 py-2 text-sm font-normal text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto disabled:opacity-50"
                    >
                        @if (isLoading()) {
                            Wachtwoord instellen...
                        } @else {
                            Wachtwoord instellen
                        }
                    </button>
                </div>
                <div class="flex justify-center sm:mt-0 sm:justify-start pl-4">
                    <button
                        (click)="onCancel()"
                        type="button"
                        class="inline-flex w-full justify-center bg-white py-1 text-sm font-normal text-gray-400 underline hover:text-gray-600 sm:w-auto"
                    >
                        Terug naar inloggen
                    </button>
                </div>
            </div>
        </form>
</div>
<shell-footer></shell-footer>
