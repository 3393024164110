import { Component, OnInit, signal } from '@angular/core';
import { Footer } from '../../../core/shell/footer/footer';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import {
    FormControlInvalidDirective
} from '../../../core/directives/forms/form-control-invalid.directive';
import { AuthServerErrorService } from '../../services/auth-server-error.service';
import { mustMatch } from '../../../core/validators/must-match.validator';

@Component({
  selector: 'app-reset-password',
  standalone: true,
    imports: [
        Footer,
        ReactiveFormsModule,
        FormControlInvalidDirective
    ],
  templateUrl: './reset-password.component.html'
})
export class ResetPasswordComponent implements OnInit {
    resetPasswordForm: FormGroup;
    isLoading = signal<boolean>(false);
    errorMessage = signal<string>('');
    private token: string | null = null;

    constructor(
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private authServerErrorService: AuthServerErrorService,
    ) {
        this.resetPasswordForm = this.fb.nonNullable.group({
            email: this.fb.control('', { validators: [ Validators.required, Validators.email ] }),
            password: this.fb.control('', { validators: [ Validators.required, Validators.minLength(6) ] }),
            passwordConfirmation: this.fb.control('', { validators: [ Validators.required ] })
        }, {
            validators: [mustMatch('password', 'passwordConfirmation')]
        });
    }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            this.token = params['token'];
            if (!this.token) {
                this.router.navigate(['/']);
            }
        });
    }

    async onSubmit() {
        if (this.resetPasswordForm.valid && this.token) {
            this.isLoading.set(true);
            this.errorMessage.set('');
            this.authService.resetPassword(this.token, this.resetPasswordForm.value.email, this.resetPasswordForm.value.password, this.resetPasswordForm.value.passwordConfirmation).subscribe({
                error: error => {
                    const errorMessage = this.authServerErrorService.getErrorMessage(error);
                    this.errorMessage.set(errorMessage);
                    this.isLoading.set(false);
                },
                complete: () => {
                    this.isLoading.set(false);
                    this.router.navigate(['/']);
                }
            });
        }
    }

    onCancel() {
        this.router.navigate(['/']);
    }
}
