import {
    APP_INITIALIZER,
    ApplicationConfig,
    ErrorHandler,
    importProvidersFrom,
    LOCALE_ID
} from '@angular/core';
import {
    provideRouter,
    Router,
    withComponentInputBinding,
    withRouterConfig
} from '@angular/router';
import { LeafletModule } from '@bluehalo/ngx-leaflet';
import { LeafletMarkerClusterModule } from '@bluehalo/ngx-leaflet-markercluster';
import { routes } from './app.routes';
import { GraphQLModule } from '../graphql/graphql.module';
import { provideHttpClient, withJsonpSupport } from '@angular/common/http';
import { NgIconsModule } from '@ng-icons/core';
import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import * as Sentry from '@sentry/angular';
import {
    heroAdjustmentsVertical,
    heroArrowsRightLeft,
    heroArrowsUpDown,
    heroArrowTopRightOnSquare,
    heroArrowTrendingUp,
    heroArrowUpRight,
    heroBars2,
    heroBars3,
    heroBell,
    heroBuildingOffice,
    heroBuildingOffice2,
    heroChevronDoubleLeft,
    heroChevronDoubleUp,
    heroChevronDown,
    heroChevronRight,
    heroChevronUp,
    heroChevronUpDown,
    heroCog,
    heroCreditCard,
    heroCursorArrowRays,
    heroDevicePhoneMobile,
    heroEnvelope,
    heroEye,
    heroFingerPrint,
    heroGlobeEuropeAfrica,
    heroMagnifyingGlass,
    heroMapPin,
    heroMinus,
    heroPencil,
    heroPhone,
    heroPhoto,
    heroPlus,
    heroSpeakerWave,
    heroSquares2x2,
    heroUser,
    heroUserCircle,
    heroUserPlus,
    heroUsers,
    heroXMark
} from '@ng-icons/heroicons/outline';

import {
    heroCalendarSolid,
    heroEnvelopeSolid,
    heroMagnifyingGlassSolid,
    heroMapPinSolid,
    heroPhoneSolid,
    heroPlayCircleSolid
} from '@ng-icons/heroicons/solid';

import { ionCarOutline, ionPeopleOutline } from '@ng-icons/ionicons';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    heroAdjustmentsVerticalMini,
    heroChevronLeftMini,
    heroChevronRightMini,
    heroChevronUpDownMini
} from '@ng-icons/heroicons/mini';
import { ReactiveFormsModule } from '@angular/forms';
import {
    currentUserFromLocalStorageInitializer
} from './core/initializers/current-user-initializer';
import { CURRENT_ITEM_SERVICE_CONFIG } from './search/services/current-item.service';
import { provideIntercom } from './intercom/intercom.provider';

registerLocaleData(localeNl);

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(routes),
        provideHttpClient(
            withJsonpSupport()
        ),
        importProvidersFrom(
            GraphQLModule,
            NgIconsModule.withIcons({
                heroUserPlus,
                heroSpeakerWave,
                heroBars2,
                heroUser,
                heroArrowsUpDown,
                heroBuildingOffice,
                heroSquares2x2,
                heroMagnifyingGlass,
                heroMagnifyingGlassSolid,
                ionCarOutline,
                ionPeopleOutline,
                heroChevronRight,
                heroChevronUpDownMini,
                heroMapPinSolid,
                heroCalendarSolid,
                heroEnvelopeSolid,
                heroChevronLeftMini,
                heroChevronRightMini,
                heroXMark,
                heroChevronDoubleLeft,
                heroChevronDoubleUp,
                heroAdjustmentsVerticalMini,
                heroAdjustmentsVertical,
                heroMapPin,
                heroChevronUp,
                heroChevronDown,
                heroCog,
                heroArrowTrendingUp,
                heroEye,
                heroBars3,
                heroPencil,
                heroEnvelope,
                heroArrowsRightLeft,
                heroPlus,
                heroMinus,
                heroPhone,
                heroPhoneSolid,
                heroCursorArrowRays,
                heroUserCircle,
                heroUsers,
                heroBell,
                heroFingerPrint,
                heroCreditCard,
                heroDevicePhoneMobile,
                heroPlayCircleSolid,
                heroArrowUpRight,
                heroArrowTopRightOnSquare,
                heroGlobeEuropeAfrica,
                heroChevronUpDown,
                heroBuildingOffice2,
                heroPhoto
            }),
            ReactiveFormsModule,
            BrowserAnimationsModule,
            LeafletModule,
            LeafletMarkerClusterModule
        ),
        provideRouter(routes,
            withRouterConfig({
                paramsInheritanceStrategy: 'always'
            }),
            withComponentInputBinding()
        ),
        {
            provide: CURRENT_ITEM_SERVICE_CONFIG,
            useValue: {
                pollInterval: 30000 // Poll every 30 seconds
            }
        },

        {
            provide: APP_INITIALIZER,
            useFactory: currentUserFromLocalStorageInitializer,
            multi: true
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler()
        },
        {
            provide: Sentry.TraceService,
            deps: [ Router ]
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {
            },
            deps: [ Sentry.TraceService ],
            multi: true
        },
        { provide: LOCALE_ID, useValue: 'nl' },
        { provide: 'googleTagManagerId', useValue: 'GTM-TBMBX2P' },
        provideIntercom({
            appId: 'a1pmql11',
            region: 'us',
            hideLauncher: true
        })
    ]
};
