<app-auth-wrapper>
<form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
    <div class="flex flex-column">
        <div class="flex-auto">
            <div class="grid sm:grid-cols-12 grid-cols-6 gap-x-8 mx-2 space-y-6 sm:space-y-0">
                <div class="col-span-5 items-center h-full flex mt-2">
                    <div>
                        <h3 class="text-lg font-semibold leading-6 text-gray-900" id="modal-title">
                            Wachtwoord vergeten
                        </h3>
                        <div class="mt-4">
                            <p class="text-sm text-gray-500">
                                Voer je e-mailadres in en we sturen je een link om je wachtwoord te resetten.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-span-1">
                </div>

                <div class="col-span-6 flex items-center justify-center flex-col">
                    <div class="w-full space-y-6 mb-6">
                        <div>
                            <label for="email" class="block text-sm font-medium leading-6 text-black">
                                E-mailadres
                            </label>
                            <div class="mt-2">
                                <input
                                    id="email"
                                    formControlName="email"
                                    type="email"
                                    autocomplete="email"
                                    required
                                    class="w-full rounded-sm border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-gray-400 sm:text-sm sm:leading-6"
                                >
                            </div>
                            <div
                                class="text-red-400 text-sm mt-1"
                                *formControlInvalid="forgotPasswordForm.get('email')"
                            >
                                <p *formControlInvalid="forgotPasswordForm.get('email'); error: 'required'">
                                    E-mailadres is verplicht.
                                </p>
                                <p *formControlInvalid="forgotPasswordForm.get('email'); error: 'email'">
                                    Voer een geldig e-mailadres in.
                                </p>
                            </div>
                        </div>

                        @if (errorMessage()) {
                            <div class="text-red-400 text-sm">
                                {{ errorMessage() }}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="sm:flex sm:flex-row-reverse sm:pt-6 border-t mt-5 border-t-gray-100">
        <div class="flex-grow sm:flex items-center gap-x-5 flex-row-reverse gap-y-4 sm:gap-y-0">
            <button
                type="submit"
                [disabled]="forgotPasswordForm.invalid || isLoading()"
                class="inline-flex w-full justify-center rounded-sm bg-red-600 px-3 py-2 text-sm font-normal text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto disabled:opacity-50"
            >
                @if (isLoading()) {
                    Verzenden...
                } @else {
                    Verzenden
                }
            </button>
        </div>
        <div class="flex justify-center sm:mt-0 sm:justify-start pl-4">
            <button
                (click)="onCancel()"
                type="button"
                class="inline-flex w-full justify-center bg-white py-1 text-sm font-normal text-gray-400 underline hover:text-gray-600 sm:w-auto"
            >
                Terug naar inloggen
            </button>
        </div>
    </div>
</form>
</app-auth-wrapper>
