import { Component, OnDestroy, signal } from '@angular/core';
import { AuthWrapperComponent } from '../auth-wrapper/auth-wrapper.component';
import {
    FormControlInvalidDirective
} from '../../../core/directives/forms/form-control-invalid.directive';
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { Subject, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { ModalService } from '../../../core/services/modal.service';
import { ForgotPasswordComponent } from '../forgot-password-modal/forgot-password-modal.component';
import { AuthViewService } from '../../services/auth-view.service';

@Component({
    selector: 'app-login-modal',
    standalone: true,
    imports: [
        AuthWrapperComponent,
        FormControlInvalidDirective,
        ReactiveFormsModule
    ],
    templateUrl: './login-modal.component.html',
    styleUrl: './login-modal.component.scss'
})
export class LoginModalComponent implements OnDestroy {
    protected readonly destroy$ = new Subject<void>();

    public readonly loginForm = this.formBuilder.group({
        email: new FormControl('', [ Validators.required, Validators.email ]),
        password: new FormControl('', [ Validators.required, Validators.minLength(4) ])
    });

    public readonly isLoading = signal(false);
    public readonly errorMessage = signal('');

    constructor(
        private readonly authService: AuthService,
        private readonly formBuilder: FormBuilder,
        private readonly modalService: ModalService,
        private readonly router: Router,
        private authViewService: AuthViewService,
    ) {
    }

    public onSubmit() {
        if (this.loginForm.invalid) {
            this.loginForm.markAllAsTouched();
            return;
        }

        this.isLoading.set(true)
        this.errorMessage.set('');

        this.authService.signIn(
            this.loginForm.value.email!,
            this.loginForm.value.password!
        ).pipe(
            catchError(error => {
                this.errorMessage.set('E-mail of wachtwoord is onjuist. Probeer het opnieuw.');
                return throwError(() => error);
            }),
            finalize(() => {
                this.isLoading.set(false);
            })
        ).subscribe(() => {
            this.router.navigate([ '/','bedrijf','beheer','aanbod' ]);
            this.modalService.close();
        });
    }

    public ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public onForgotPasswordClick() {
        this.authViewService.openForgotPasswordModal();
    }

    public onSignUpClick() {
        this.router.navigate([ '/sign-up' ]);
    }

    public onCancel() {
        this.modalService.close();
    }
}
