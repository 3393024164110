// auth-server-error.service.ts
import { Injectable } from '@angular/core';

export enum AuthErrorCode {
    INVALID_USER = 1,
    INVALID_TOKEN = 2,
    RESET_THROTTLED = 3,
    PASSWORD_USED_BEFORE = 4,
    EMAIL_ADDRESS_USED = 5,
    CURRENT_PASSWORD_INVALID = 6,
    CANNOT_DELETE_PROFILE_OWNING_COMPANY = 7
}

@Injectable({
    providedIn: 'root'
})
export class AuthServerErrorService {
    private errorMessages = new Map<number, string>([
        [AuthErrorCode.INVALID_USER, 'Deze gebruiker bestaat niet.'],
        [AuthErrorCode.INVALID_TOKEN, 'De reset link is ongeldig of verlopen. Vraag een nieuwe aan.'],
        [AuthErrorCode.RESET_THROTTLED, 'Je hebt te vaak geprobeerd je wachtwoord te resetten. Probeer het over een paar minuten opnieuw.'],
        [AuthErrorCode.PASSWORD_USED_BEFORE, 'Dit wachtwoord heb je al eerder gebruikt. Kies een nieuw wachtwoord.'],
        [AuthErrorCode.EMAIL_ADDRESS_USED, 'Dit e-mailadres is al in gebruik.'],
        [AuthErrorCode.CURRENT_PASSWORD_INVALID, 'Je huidige wachtwoord is onjuist.'],
        [AuthErrorCode.CANNOT_DELETE_PROFILE_OWNING_COMPANY, 'Je kunt je profiel niet verwijderen omdat je nog eigenaar bent van een bedrijf.']
    ]);

    private defaultError = 'Er is een onverwachte fout opgetreden. Probeer het opnieuw.';

    getErrorMessage(errorCode: number | null): string {
        if (!errorCode) {
            return this.defaultError;
        }
        return this.errorMessages.get(errorCode) || this.defaultError;
    }

    isAuthError(error: any): boolean {
        return error?.response?.data?.code !== undefined;
    }

    getErrorFromResponse(error: any): string {
        if (this.isAuthError(error)) {
            return this.getErrorMessage(error.response.data.code);
        }
        return this.defaultError;
    }
}
