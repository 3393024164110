import { DestroyRef, inject, Injectable, InjectionToken } from '@angular/core';
import {
    boot,
    hide,
    Intercom,
    onHide,
    onShow,
    onUnreadCountChange,
    show,
    showMessages,
    showNewMessage,
    shutdown,
    trackEvent,
    update
} from '@intercom/messenger-js-sdk';
import { IntercomSettings, Regions, UserArgs } from '@intercom/messenger-js-sdk/dist/types';
import { AuthService } from '../auth/services/auth.service';
import { toObservable } from '@angular/core/rxjs-interop';

export interface IntercomConfig {
    appId: string;
    region?: Regions;
    hideLauncher?: boolean;
}

export const INTERCOM_CONFIG = new InjectionToken<IntercomConfig>('INTERCOM_CONFIG');

@Injectable({
    providedIn: 'root'
})
export class IntercomService {
    private readonly authService = inject(AuthService);
    private readonly destroyRef = inject(DestroyRef);

    private readonly config = inject(INTERCOM_CONFIG);

    private readonly currentUser$ = toObservable(this.authService.currentUser);

    constructor() {
        // Initialize Intercom with base configuration
        this.initializeIntercom();

        // Watch for user changes and update Intercom accordingly
        this.watchAuthChanges();

        // Clean up on destroy
        this.destroyRef.onDestroy(() => {
            this.shutdown();
        });
    }

    private initializeIntercom(): void {
        if (typeof window === 'undefined') return;

        const settings: IntercomSettings = {
            app_id: this.config.appId,
            region: this.config.region,
            hide_default_launcher: this.config.hideLauncher,
            alignment: 'right',
            horizontal_padding: 20,
            vertical_padding: 20
        };

        Intercom(settings);
    }

    private watchAuthChanges(): void {
        // Subscribe to user changes
        this.currentUser$.subscribe(user => {
            if (!user) {
                this.shutdown();
                return;
            }

            const employee = this.authService.currentEmployee();

            // Prepare user data for Intercom
            const userData: UserArgs = {
                user_id: user.id,
                email: user.email,
                name: user.firstName + ' ' + user.lastName,
                created_at: this.convertToUnixTimestamp(user.createdAt),
                // Add custom attributes
                custom_attributes: {
                    is_imposter: this.authService.isImposter(),
                    company_id: employee?.companyId,
                    company_name: employee?.company?.name
                    // role: user.role
                }
            };

            // If user has a company, add company data
            if (employee?.company) {
                userData['company'] = {
                    company_id: employee.companyId,
                    name: employee.company.name
                };
            }

            update(userData);
        });
    }

    private convertToUnixTimestamp(dateString: string | undefined): number {
        if (!dateString) return Math.floor(Date.now() / 1000);
        return Math.floor(new Date(dateString).getTime() / 1000);
    }

    /**
     * Shutdown the Intercom instance and reinitialize with base config
     */
    public shutdown(): void {
        shutdown();
        this.initializeIntercom();
    }

    /**
     * Show the Intercom messenger
     */
    public show(): void {
        show();
    }

    /**
     * Hide the Intercom messenger
     */
    public hide(): void {
        hide();
    }

    /**
     * Show the messages view
     */
    public showMessages(): void {
        showMessages();
    }

    /**
     * Show new message view with optional pre-populated content
     */
    public showNewMessage(message?: string): void {
        showNewMessage(message || '');
    }

    /**
     * Track an event with optional metadata
     */
    public trackEvent(eventName: string, metadata?: Record<string, any>): void {
        trackEvent(eventName, metadata);
    }

    /**
     * Register a callback for when the messenger is hidden
     */
    public onHide(callback: () => void): void {
        onHide(callback);
    }

    /**
     * Register a callback for when the messenger is shown
     */
    public onShow(callback: () => void): void {
        onShow(callback);
    }

    /**
     * Register a callback for when the unread count changes
     */
    public onUnreadCountChange(callback: (unreadCount: number) => void): void {
        onUnreadCountChange(callback);
    }

    /**
     * Boot Intercom with custom settings
     */
    public boot(settings: IntercomSettings): void {
        boot({
            ...this.config,
            ...settings
        });
    }
}
