// Provider factory for Intercom
import { makeEnvironmentProviders } from '@angular/core';
import { INTERCOM_CONFIG, IntercomConfig, IntercomService } from './intercom.service';

export function provideIntercom(config: IntercomConfig) {
    return makeEnvironmentProviders([
        {
            provide: INTERCOM_CONFIG,
            useValue: config
        },
        IntercomService
    ]);
}
