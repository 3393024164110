import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MainTitleResolver {
    constructor(private titleService: Title) {}

    resolve: ResolveFn<string> = (route: ActivatedRouteSnapshot): Observable<string> => {
        const titles: { [key: string]: string } = {
            '': 'Home',
            'contact': 'Contact',
            'netwerkavonden': 'Netwerkavonden',
            'algemene-voorwaarden': 'Algemene Voorwaarden',
            'bedrijven': 'Bedrijven',
            'vraag': 'Vraag',
            'aanbod': 'Aanbod',
            'register': 'Registreren',
            'reset-password': 'Wachtwoord Resetten'
        };

        const title = `Planmeister | ${titles[route.routeConfig?.path || '']}`;
        this.titleService.setTitle(title);
        return of(title);
    };
}
