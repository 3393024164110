import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Component, signal } from '@angular/core';
import { ModalService } from '../../../core/services/modal.service';
import { CommonModule } from '@angular/common';
import { AuthWrapperComponent } from '../auth-wrapper/auth-wrapper.component';
import {
    FormControlInvalidDirective
} from '../../../core/directives/forms/form-control-invalid.directive';
import { AuthService } from '../../services/auth.service';

@Component({
    selector: 'app-forgot-password-modal',
    standalone: true,
    imports: [ CommonModule, ReactiveFormsModule, AuthWrapperComponent, FormControlInvalidDirective ],
    templateUrl: './forgot-password-modal.component.html',
})

export class ForgotPasswordComponent {
    forgotPasswordForm: FormGroup;
    isLoading = signal<boolean>(false);
    errorMessage = signal<string>('');

    constructor(
        private fb: FormBuilder,
        private modalService: ModalService,
        private authService:AuthService,

    ) {
        this.forgotPasswordForm = this.fb.group({
            email: ['', [Validators.required, Validators.email]]
        });
    }

    async onSubmit() {
        if (this.forgotPasswordForm.valid) {
            this.isLoading.set(true);
            this.errorMessage.set('');
            this.authService.forgotPassword(this.forgotPasswordForm.value.email).subscribe({
                error: error => {
                    this.errorMessage.set('E-mail is onjuist. Probeer het opnieuw.');
                    this.isLoading.set(false);
                },
                complete: () => {
                    this.isLoading.set(false);
                    this.modalService.close();
                }
            });
            }
        }

    onCancel() {
       this.modalService.close();
    }
}
